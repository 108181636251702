const leaflets = {
  "colantoni": {
    "config": {
      "primary-color": "rgb(19, 42, 60)",
      "secondary-color": "rgb(140, 187, 45)",
      "tertiary-color": "#ffdd00",
      "hover-color": "rgba(220, 35, 17, 0.5)",
      "primary-alpha-color": "rgba(35, 94, 157, 0.7)",
      "clientIcon": require("assets/images/logo-colantoni.png"),
      "serverPath": "colantoni.interattivo.net/",
      "pdfPath": "/media/pdf/volantino.pdf",
      "shareFlyerTitle": "Colantoni | Volantino interattivo",
      "shareFlyerURL": "https://colantoni.interattivo.net?v=202",
      "shareProductSentence": "Guarda il Volantino di Colantino",
      "ogTitleMeta": "Colantoni | Volantino interattivo",
      "ogDescriptionMeta": "Dai uno sguardo al Volantino Interattivo di Colantoni. Offerte valide dal 09/11 al 21/11",
      "ogImageMeta": "https://colantoni.interattivo.net/media/images/colantoni_fb.png",
      "filterBrand": 0,
      "filterUnderprice": 0,
      "brandImage": "",
      "underPriceImage": "",
      "ga_active": false,
      "ga_tracking_id": "UA-152069090-0",
      "release_id": "1",
      "send_grocery_list_to_market": true,
      "grocery_list_min_value": 10,
      "client_id": 10,
      "signboard_id": 11,
      "has_pages": true,
      "hide_grocery_list": false,
      "hide_searchbar": false,
      "line_through": false,
      "category_banner": "/media/images/banner.png",
      "category_banner_mobile": "/media/images/banner.png",
      "hide_plus_product": false,
      "hide_plus_product_price": false,
      "product_banner": "",
      "href_banner": "",
      "internal_banner_click": false,
      "external_banner_click": false,
      "hide_listPages": false,
      "type": "leaflet"
    },
    "leaflet": {
      "name": "Demo Colantoni",
      "code": "202",
      "id_campaign": "202",
      "index": {
        "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/Indice-colantoni_g4leodV_X0g66UM.png",
        "links": [
          {
            "page": 2,
            "blueprint": {
              "top": 27.549769873614377,
              "left": 13.18935275379107,
              "width": 75.97022281379991,
              "height": 6.52209390937667
            }
          },
          {
            "page": 4,
            "blueprint": {
              "top": 34.09309341938365,
              "left": 13.096002747456165,
              "width": 75.97022281379991,
              "height": 6.52209390937667
            }
          },
          {
            "page": 5,
            "blueprint": {
              "top": 40.428727748969784,
              "left": 12.914919228943438,
              "width": 75.97022281379991,
              "height": 6.52209390937667
            }
          },
          {
            "page": 7,
            "blueprint": {
              "top": 46.88173777457267,
              "left": 12.770561700321188,
              "width": 75.97022281379991,
              "height": 6.52209390937667
            }
          },
          {
            "page": 10,
            "blueprint": {
              "top": 53.415918901823616,
              "left": 12.790459726875131,
              "width": 75.97022281379991,
              "height": 6.52209390937667
            }
          },
          {
            "page": -1,
            "type": "url",
            "url": "https://volantinointerattivo.net",
            "target": "_blank",
            "blueprint": {
              "top": 6.771427583584,
              "left": 61.01607717041801,
              "width": 30.0,
              "height": 6.928741831760189
            }
          }
        ]
      },
      "categories": [
        {
          "category_name": "Alimentari",
          "subcategories": [
            "Olio",
            "Dispensa",
            "Pasta",
            "Rossi",
            "Snack Salati",
            "Snack Dolci",
            "Colazione"
          ]
        },
        {
          "category_name": "Igiene Casa",
          "subcategories": [
            "Extra"
          ]
        },
        {
          "category_name": "Bevande",
          "subcategories": [
            "Bibite"
          ]
        },
        {
          "category_name": "Self Service",
          "subcategories": [
            "Latte"
          ]
        }
      ],
      "pages": [
        {
          "number": 1,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_1_5IpQcNd.jpg",
          "products": [
            {
              "item_id": "cstm66552619",
              "field1": "BARBERA",
              "field2": "famiglia olio extravergine di oliva",
              "field3": "1 litro",
              "field4": "",
              "description": "BARBERA famiglia olio extravergine di oliva 1 litro",
              "grammage": null,
              "price": "2.99",
              "price_label": "€ 2,99",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Olio",
              "blueprint": {
                "top": 60.71100022301516,
                "left": 4.499266489183765,
                "width": 21.83430495929973,
                "height": 23.105416480820697
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/1_SXueSRE.png",
                  "cropped": false
                },
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_DW6P01z_eNw0Swj.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                },
                {
                  "type": "world",
                  "title": "",
                  "data": "https://www.oliobarbera.it/it/"
                }
              ]
            },
            {
              "item_id": "cstm11010577",
              "field1": "MASTROLINDO",
              "field2": "bagno/gel con candeggina/ pavimenti vari tipi",
              "field3": "950 ml ",
              "field4": "",
              "description": "MASTROLINDO bagno/gel con candeggina/ pavimenti vari tipi 950 ml ",
              "grammage": null,
              "price": "0.99",
              "price_label": "€ 0,99",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [
                "bagno",
                "gel con candeggina",
                "pavimenti vari tipi"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Igiene Casa",
              "subcategory": "Extra",
              "blueprint": {
                "top": 60.89289696699376,
                "left": 28.366941834857275,
                "width": 21.83430495929973,
                "height": 23.105416480820697
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/Schermata_2020-11-11_alle_15.46.10_hB9eVAu.png",
                  "cropped": false
                },
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/1_UqPPFIX.jpg",
                  "cropped": false
                },
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_SNx2kLd_2mh418b.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                },
                {
                  "type": "info",
                  "title": "",
                  "data": {
                    "titolo": "Mastrolindo",
                    "testo": "La soluzione ideale per pulire in modo semplice e veloce.\nGrazie alla formula di Mastro Lindo, il pulito brilla ancora di più su tutte le superfici di casa: scioglie il grasso, annienta lo sporco, lasciando un'intensa freschezza e un pulito splendente.\nSprigiona la brillantezza su tutte le superfici lavabili della tua casa e scopri la pulizia profonda di Mastro Lindo.\n(fonte: desiderimagazine.it)",
                    "img": [
                      "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/images/Schermata_2020-11-11_alle_15.45.57.png"
                    ]
                  }
                }
              ]
            }
          ]
        },
        {
          "number": 2,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_2_7uoKSjR.jpg",
          "products": [
            {
              "item_id": "cstm02152855",
              "field1": "Morettino",
              "field2": "caps compatibili",
              "field3": "a modo mio/ nespresso",
              "field4": "30 pezzi 240/290g",
              "description": "Morettino caps compatibili a modo mio/ nespresso 30 pezzi 240/290g",
              "grammage": null,
              "price": "5.99",
              "price_label": "€ 5,99",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [
                "a modo mio",
                "nespresso"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 25.7425708073149,
                "left": 4.479822354482605,
                "width": 23.235740967885818,
                "height": 25.00313615075825
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_DDo8GCO_a3PK1sb.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm86502934",
              "field1": "Santa Rosa",
              "field2": "confettura classica vari tipi",
              "field3": "350 g",
              "field4": "",
              "description": "Santa Rosa confettura classica vari tipi 350 g",
              "grammage": null,
              "price": "1.29",
              "price_label": "€ 1,29",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 49.702065677966104,
                "left": 3.5353235113737735,
                "width": 23.235740967885818,
                "height": 19.687012154326492
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_lgUXeUe_LD42cS3.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm89909898",
              "field1": "morettino",
              "field2": "qualità bar caffè",
              "field3": "2x250 g",
              "field4": "",
              "description": "morettino qualità bar caffè 2x250 g",
              "grammage": null,
              "price": "3.99",
              "price_label": "€ 3,99",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 70.09959020963426,
                "left": 3.537754028211418,
                "width": 23.235740967885818,
                "height": 22.21126505352364
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_2tmsuQN_JVGfqRA.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm08010358",
              "field1": "rummo",
              "field2": "pasta di semola vari formati classici",
              "field3": "500 g",
              "field4": "",
              "description": "rummo pasta di semola vari formati classici 500 g",
              "grammage": null,
              "price": "0.69",
              "price_label": "€ 0,69",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Pasta",
              "blueprint": {
                "top": 8.127160459411241,
                "left": 28.172986591213206,
                "width": 21.243689367752008,
                "height": 17.078431645851918
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_pToAWwQ_ShuIy7t.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm42065705",
              "field1": "Scotti",
              "field2": "riso per risotti ",
              "field3": "1kg",
              "field4": "",
              "description": "Scotti riso per risotti  1kg",
              "grammage": null,
              "price": "0.99",
              "price_label": "€ 0,99",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Pasta",
              "blueprint": {
                "top": 8.119494313113293,
                "left": 51.68434816848796,
                "width": 21.243689367752008,
                "height": 17.078431645851918
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_XZdn5g1_Wz4fnok.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm31734758",
              "field1": "Barilla",
              "field2": "farina 00",
              "field3": "1 kg",
              "field4": "",
              "description": "Barilla farina 00 1 kg",
              "grammage": null,
              "price": "0.59",
              "price_label": "€ 0,59",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 8.58503847011597,
                "left": 73.9279521632471,
                "width": 21.243689367752008,
                "height": 17.078431645851918
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_jUY9M8h_FONmVMq.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm68035167",
              "field1": "gico",
              "field2": "olio di semi di girasole",
              "field3": "1 litro",
              "field4": "",
              "description": "gico olio di semi di girasole 1 litro",
              "grammage": null,
              "price": "1.19",
              "price_label": "€ 1,19",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Olio",
              "blueprint": {
                "top": 25.850245316681534,
                "left": 27.836603060883142,
                "width": 21.157162968331846,
                "height": 15.852893621766281
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_6jz7R9w_sUcORgR.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm06135427",
              "field1": "la doria",
              "field2": "polpa di pomodoro 3x400g",
              "field3": "",
              "field4": "",
              "description": "la doria polpa di pomodoro 3x400g",
              "grammage": null,
              "price": "1.19",
              "price_label": "€ 1,19",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 25.79240075825156,
                "left": 51.520531333630686,
                "width": 21.157162968331846,
                "height": 15.852893621766281
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_rzrccrE_sOGQfrC.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm36370136",
              "field1": "valfrutta",
              "field2": "passata di pomodoro a vapore",
              "field3": "",
              "field4": "",
              "description": "valfrutta passata di pomodoro a vapore",
              "grammage": null,
              "price": "0.59",
              "price_label": "€ 0,59",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Rossi",
              "blueprint": {
                "top": 25.706679304192686,
                "left": 73.99552053133363,
                "width": 21.157162968331846,
                "height": 15.852893621766281
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_zlwi8bd_GXqhI04.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm58352444",
              "field1": "barilla",
              "field2": "sugo arrabbiata, basilico, pomodoro",
              "field3": "400 g",
              "field4": "",
              "description": "barilla sugo arrabbiata, basilico, pomodoro 400 g",
              "grammage": null,
              "price": "0.99",
              "price_label": "€ 0,99",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [
                "arrabbiata",
                "basilico",
                "pomodoro"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Rossi",
              "blueprint": {
                "top": 42.43630129348796,
                "left": 27.573621139049955,
                "width": 23.08504892395183,
                "height": 16.455383028545942
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_35FLNJZ_jq3C7CF.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm62309185",
              "field1": "noi voi",
              "field2": "maionese",
              "field3": "500 ml",
              "field4": "",
              "description": "noi voi maionese 500 ml",
              "grammage": null,
              "price": "1.09",
              "price_label": "€ 1,09",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 42.70357103033007,
                "left": 50.20756613793488,
                "width": 23.08504892395183,
                "height": 16.455383028545942
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/Schermata_2020-11-11_alle_15.51.40_6AzcoBK.png",
                  "cropped": false
                },
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_D6NbniG_sgyDmlo.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                },
                {
                  "type": "specs",
                  "title": "INFORMAZIONI NUTRIZIONALI",
                  "data": {
                    "specifiche": "Valore energetico (calorie) 688 kcal, Proteine 0 g, Carboidrati 0.3 g, zuccheri 0.3 g, Grassi\t77.8\tg, saturi 10.784 g, monoinsaturi 18.026 g, polinsaturi 45.539 g, colesterolo\t0 mg, Fibra alimentare 0 g, Sodio 486 mg\n",
                    "img": [
                      "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/images/Schermata_2020-11-11_alle_15.51.24.png"
                    ]
                  }
                }
              ]
            },
            {
              "item_id": "cstm54997399",
              "field1": "heinz",
              "field2": "tomato top down",
              "field3": "250 g",
              "field4": "",
              "description": "heinz tomato top down 250 g",
              "grammage": null,
              "price": "0.99",
              "price_label": "€ 0,99",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 42.59485113737735,
                "left": 73.21726903991971,
                "width": 23.08504892395183,
                "height": 16.455383028545942
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_4V2EILs_j4dPEUw.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm64319736",
              "field1": "nostromo",
              "field2": "tonno all'olio di oliva ",
              "field3": "3x80 g",
              "field4": "",
              "description": "nostromo tonno all'olio di oliva  3x80 g",
              "grammage": null,
              "price": "1.99",
              "price_label": "€ 1,99",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 59.01469112399643,
                "left": 28.032988821364853,
                "width": 21.01084585470562,
                "height": 16.62682593666369
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/Schermata_2020-11-11_alle_15.54.06_9nWkgFw.png",
                  "cropped": false
                },
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_iiWxX9Q_VHZQt4G.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                },
                {
                  "type": "hat-chef",
                  "title": "SPAGHETTI AL TONNO",
                  "data": {
                    "ingredients": "Una confezione Tonno Nostromo all’Olio di Oliva, 320 g Spaghetti di semola, 160 g Pomodori pelati, 1 Cipolla di medie dimensioni, q.b. Olio extravergine di oliva, q.b. Sale",
                    "recipe": "Portate a ebollizione abbondante acqua salata dove cuocere la pasta.\nIn una casseruola fate appassire una cipolla tritata in olio extravergine di oliva e unite il tonno sbriciolato.\nLasciate insaporire qualche minuto e poi aggiungete i pomodori pelati passati al setaccio e un pizzico di sale: cuocete per circa 15 minuti.\nScolate la pasta un poco al dente e buttatela nella casseruola in modo da unire il condimento e insaporire in modo uniforme la pasta.\nPortate a ebollizione abbondante acqua salata dove cuocere la pasta.\n(fonte: nostromo.it)",
                    "img": [
                      "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/images/Schermata_2020-11-11_alle_15.53.55.png"
                    ]
                  }
                }
              ]
            },
            {
              "item_id": "cstm42127757",
              "field1": "jolly colombani",
              "field2": "mais dolce",
              "field3": "3x160 g",
              "field4": "",
              "description": "jolly colombani mais dolce 3x160 g",
              "grammage": null,
              "price": "1.19",
              "price_label": "€ 1,19",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 59.517520628902766,
                "left": 50.32471704950937,
                "width": 21.01084585470562,
                "height": 16.626825936663693
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_RZTX2kt_lnS4DiL.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm08724582",
              "field1": "sole",
              "field2": "panna",
              "field3": "200 ml",
              "field4": "",
              "description": "sole panna 200 ml",
              "grammage": null,
              "price": "0.59",
              "price_label": "€ 0,59",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 59.44155608831401,
                "left": 74.22350301070472,
                "width": 21.01084585470562,
                "height": 16.626825936663693
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_c99nIgO_IRtpTsP.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm39894904",
              "field1": "pavesi",
              "field2": "cracker salati/ non salati",
              "field3": "560 g",
              "field4": "",
              "description": "pavesi cracker salati/ non salati 560 g",
              "grammage": null,
              "price": "1.39",
              "price_label": "€ 1,39",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [
                "salati",
                "non salati"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Snack Salati",
              "blueprint": {
                "top": 76.27711028099911,
                "left": 27.569246208742193,
                "width": 21.66902981433988,
                "height": 17.35824598572703
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_PEx00w2_S5ctPFg.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm22085264",
              "field1": "perugina baci",
              "field2": "classici/ gold",
              "field3": "3 pezzi",
              "field4": "",
              "description": "perugina baci classici/ gold 3 pezzi",
              "grammage": null,
              "price": "1.39",
              "price_label": "€ 1,39",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Snack Dolci",
              "blueprint": {
                "top": 76.11542428635147,
                "left": 50.79429290254238,
                "width": 21.669029814339876,
                "height": 17.35824598572703
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_pRs39jB_aloWAWO.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm84610626",
              "field1": "Perugina",
              "field2": "blocco latte/ fondente 70%",
              "field3": "",
              "field4": "",
              "description": "Perugina blocco latte/ fondente 70%",
              "grammage": null,
              "price": "1.29",
              "price_label": "€ 1,29",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [
                "latte",
                "fondente"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Snack Dolci",
              "blueprint": {
                "top": 76.65867528991971,
                "left": 73.92406333630686,
                "width": 21.669029814339876,
                "height": 17.35824598572703
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_oJOuOJW_QhzZFwV.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            }
          ]
        },
        {
          "number": 3,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_3_JInjynO.jpg",
          "products": [
            {
              "item_id": "cstm68908118",
              "field1": "mulino bianco",
              "field2": "fette biscottate vari tipi ",
              "field3": "speciali 32 pezzi 315g",
              "field4": "",
              "description": "mulino bianco fette biscottate vari tipi  speciali 32 pezzi 315g",
              "grammage": null,
              "price": "1.39",
              "price_label": "€ 1,39",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Colazione",
              "blueprint": {
                "top": 13.921373215878681,
                "left": 5.650359263492417,
                "width": 26.007988472903655,
                "height": 13.21051237734166
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_anvDJs8_6FimVKC.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm64262729",
              "field1": "Mulino bianco",
              "field2": "rustici/ classici",
              "field3": "vari tipi 700/800 g",
              "field4": "",
              "description": "Mulino bianco rustici/ classici vari tipi 700/800 g",
              "grammage": null,
              "price": "2.39",
              "price_label": "€ 2,39",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Colazione",
              "blueprint": {
                "top": 27.63645740410348,
                "left": 5.160853172390723,
                "width": 26.007988472903655,
                "height": 13.21051237734166
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_w77eLfF_VXTImUw.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                },
                {
                  "type": "play",
                  "title": "",
                  "data": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/video/Mulino_Bianco_-_Spot_2015_-_Macine_30.mp4"
                }
              ]
            },
            {
              "item_id": "cstm66535710",
              "field1": "nutkao",
              "field2": "crema spalmabile alla nocciola",
              "field3": "bicolore/ cacao",
              "field4": "200 g",
              "description": "nutkao crema spalmabile alla nocciola bicolore/ cacao 200 g",
              "grammage": null,
              "price": "1.39",
              "price_label": "€ 1,39",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [
                "bicolore",
                "cacao"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 42.293432203389834,
                "left": 3.4594913860392507,
                "width": 26.007988472903655,
                "height": 16.575602140945584
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_4Ifa1LI_m2uEwhH.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm39730956",
              "field1": "valsoia",
              "field2": "soya drink",
              "field3": "morbido/ classico/ light",
              "field4": "1 litro",
              "description": "valsoia soya drink morbido/ classico/ light 1 litro",
              "grammage": null,
              "price": "1.59",
              "price_label": "€ 1,59",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Bevande",
              "subcategory": "Bibite",
              "blueprint": {
                "top": 59.54783675289921,
                "left": 3.7550422334968774,
                "width": 26.007988472903655,
                "height": 16.575602140945584
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_nsUInDR_BtRLVQf.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm42810961",
              "field1": "parmalat",
              "field2": "latte p.s. ",
              "field3": "6x1 litro",
              "field4": "",
              "description": "parmalat latte p.s.  6x1 litro",
              "grammage": null,
              "price": "4.39",
              "price_label": "€ 4,39",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Self Service",
              "subcategory": "Latte",
              "blueprint": {
                "top": 75.86104761373774,
                "left": 4.09677290086976,
                "width": 26.007988472903655,
                "height": 16.575602140945584
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_YdHV7Y9_9WvIwCH.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm01682307",
              "field1": "gran cereale",
              "field2": "biscotto",
              "field3": "500 g",
              "field4": "",
              "description": "gran cereale biscotto 500 g",
              "grammage": null,
              "price": "1.49",
              "price_label": "€ 1,49",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Colazione",
              "blueprint": {
                "top": 9.097276427297057,
                "left": 29.885042651650316,
                "width": 30.140839303635147,
                "height": 14.742347792149866
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_QUe7pE9_DsOSeh9.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm96052143",
              "field1": "mulino bianco",
              "field2": "piadelle sfogliate",
              "field3": "330 g",
              "field4": "",
              "description": "mulino bianco piadelle sfogliate 330 g",
              "grammage": null,
              "price": "1.29",
              "price_label": "€ 1,29",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Colazione",
              "blueprint": {
                "top": 21.65233329616414,
                "left": 29.10144402319358,
                "width": 29.419948009589657,
                "height": 19.398137823371993
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_RZdshXn_c8uzmiM.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm01979590",
              "field1": "mulino bianco",
              "field2": "6 pezzi 240g",
              "field3": "",
              "field4": "",
              "description": "mulino bianco 6 pezzi 240g",
              "grammage": null,
              "price": "1.59",
              "price_label": "€ 1,59",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Colazione",
              "blueprint": {
                "top": 8.257833407671724,
                "left": 59.44353012098573,
                "width": 14.967608789585189,
                "height": 22.62175234165924
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_pw7vZZl_UknPGc3.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm02157832",
              "field1": "mulino bianco",
              "field2": "pan bauletto al gran duro",
              "field3": "400 g",
              "field4": "",
              "description": "mulino bianco pan bauletto al gran duro 400 g",
              "grammage": null,
              "price": "0.89",
              "price_label": "€ 0,89",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 22.50362399643176,
                "left": 49.21737357827833,
                "width": 22.24797892506691,
                "height": 19.65774141391615
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_86uIDvI_EWrVT09.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm86976207",
              "field1": "il granturchese",
              "field2": "biscotto classico, con cacao e cioccolato",
              "field3": "400g ",
              "field4": "",
              "description": "il granturchese biscotto classico, con cacao e cioccolato 400g ",
              "grammage": null,
              "price": "1.19",
              "price_label": "€ 1,19",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [
                "classico",
                "con cacao e cioccolato"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Colazione",
              "blueprint": {
                "top": 50.175972903657446,
                "left": 29.56421442908118,
                "width": 43.459585470562,
                "height": 33.12437276984835
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_4h0iJCZ_XYQA9oX.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                },
                {
                  "type": "play",
                  "title": "",
                  "data": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/video/spot_COLUSSI_GRANTURCHESE_2016.mp4"
                }
              ]
            },
            {
              "item_id": "cstm40394050",
              "field1": "bauli",
              "field2": "croissant farciti vari tipi",
              "field3": "6 pezzi ",
              "field4": "300 g",
              "description": "bauli croissant farciti vari tipi 6 pezzi  300 g",
              "grammage": null,
              "price": "1.39",
              "price_label": "€ 1,39",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Colazione",
              "blueprint": {
                "top": 23.751115075825158,
                "left": 74.27686810046833,
                "width": 25.72313189953167,
                "height": 22.706079950936665
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_jDOmcJC_Vu5C0CP.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm93654924",
              "field1": "pavesi",
              "field2": "ringo cioccolato/vaniglia",
              "field3": "tubo 165 g",
              "field4": "",
              "description": "pavesi ringo cioccolato/vaniglia tubo 165 g",
              "grammage": null,
              "price": "0.79",
              "price_label": "€ 0,79",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Snack Dolci",
              "blueprint": {
                "top": 45.81079950936664,
                "left": 74.11450957571365,
                "width": 25.72313189953167,
                "height": 22.706079950936665
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_nyJ53oU_PZY6Jxg.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                },
                {
                  "type": "play",
                  "title": "",
                  "data": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/video/Ringo_-_Insieme_si_vince.mp4"
                }
              ]
            },
            {
              "item_id": "cstm17825288",
              "field1": "kinder",
              "field2": "brioss classici 10 pezzi 270 g",
              "field3": "colazione più 10 pezzi 300 g",
              "field4": "",
              "description": "kinder brioss classici 10 pezzi 270 g colazione più 10 pezzi 300 g",
              "grammage": null,
              "price": "1.99",
              "price_label": "€ 1,99",
              "equivalence": 1,
              "quantity_step": 1,
              "grocery_label": "pz.",
              "varieties": [
                "brioss classici 10 pezzi 270 g",
                "colazione più 10 pezzi 300 g"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Alimentari",
              "subcategory": "Dispensa",
              "blueprint": {
                "top": 65.63440845227477,
                "left": 73.55403239295272,
                "width": 25.72313189953167,
                "height": 26.604315343443357
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_Er6Xvwi_eGj8lUe.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            }
          ]
        },
        {
          "number": 4,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_4_4r426Bu.jpg",
          "products": []
        },
        {
          "number": 5,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_5_iwUeu4r.jpg",
          "products": []
        },
        {
          "number": 6,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_6_VMNB5Zu.jpg",
          "products": []
        },
        {
          "number": 7,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_7_M5kSyG2.jpg",
          "products": [
            {
              "item_id": "cstm62036538",
              "field1": "Bollito e Spezzatino",
              "field2": "di bovino adulto",
              "field3": "secondo taglio",
              "field4": "",
              "description": "Bollito e Spezzatino di bovino adulto secondo taglio",
              "grammage": null,
              "price": "6.90",
              "price_label": "€ 6,90 al Kg",
              "equivalence": 1000,
              "quantity_step": 100,
              "grocery_label": "gr.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Macelleria",
              "subcategory": "Bovino",
              "blueprint": {
                "top": 8.183758001757251,
                "left": 4.512645914396886,
                "width": 23.034046692607003,
                "height": 16.511861428392116
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_4isNn4r_UuXpKHl.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm31885290",
              "field1": "Spiedini",
              "field2": "con pistacchio e philadelphia",
              "field3": "",
              "field4": "",
              "description": "Spiedini con pistacchio e philadelphia",
              "grammage": null,
              "price": "11.90",
              "price_label": "€ 11,90 al Kg",
              "equivalence": 1000,
              "quantity_step": 200,
              "grocery_label": "gr.",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Macelleria",
              "subcategory": "Altro",
              "blueprint": {
                "top": 25.0449771976068,
                "left": 4.150778210116732,
                "width": 23.034046692607003,
                "height": 16.511861428392116
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/products/cropped_image_Swf800Q_Xq5me2O.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            }
          ]
        },
        {
          "number": 8,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_8_UqL6A6p.jpg",
          "products": []
        },
        {
          "number": 9,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_9_iRBNqnU.jpg",
          "products": []
        },
        {
          "number": 10,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_10_icGTs7A.jpg",
          "products": []
        },
        {
          "number": 11,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_11_pyLA0FS.jpg",
          "products": []
        },
        {
          "number": 12,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/page_12_vcjo5Rq.jpg",
          "products": []
        },
        {
          "number": 13,
          "image_file": "https://interattivo.s3.amazonaws.com/6ZjWfJ14Y7/pages/fine-colantoni_vUm2WYI_eHiPyxe.png",
          "products": []
        }
      ]
    }
  }

}

const lefletSwitch = () => {
  return leaflets.colantoni;
}

export const serverResponse = lefletSwitch(); 